<template>
    <b-container fluid>
        <b-card>
            <template #header>
                活動分類 - 新增
            </template>

            <b-row>
                <b-col cols="6">
                    <div class="form-group">
                        <label for="category">中文</label>
                        <input
                            placeholder="請輸入分類"
                            v-model="categoryZh"
                            class="form-control"
                            id="categoryzh">
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="form-group">
                        <label for="category">英文</label>
                        <input
                            placeholder="請輸入分類"
                            v-model="categoryEn"
                            class="form-control"
                            id="categoryen">
                    </div>
                </b-col>
            </b-row>
        
            <router-link to="/admin/getCategories" class="btn btn-outline-secondary" tag="button">取消</router-link>
            <button @click="handleCategory" class="btn btn-primary ml-2">送出</button>
        </b-card>

    </b-container>
</template>

<script>
import { getCategories, putCategory, createCategory} from '@/apis/apiEventCategories';

export default {
    data() {
        return {
            id: '',
            categoryZh: '',
            categoryEn: '',
        }
    },

    created() {
        this.initialView();
    },

    watch: {},

    methods: {
        async initialView() {
            const categoryId = this.$route.query.category ? this.$route.query.category : '';
            this.id = categoryId;

            if (categoryId) {
                const categories = await getCategories();

                const category = categories.find(item => item.value === categoryId);
                if (category) {
                    this.categoryZh = category.text.zh;
                    this.categoryEn = category.text.en;
                }
            }
        },

        async handleCategory() {
            if (this.id) {
                await putCategory({ 
                    id: this.id,
                    category: {
                        zh: this.categoryZh,
                        en: this.categoryEn,
                    }
                })
            } else {
                await createCategory({
                    category: {
                        zh: this.categoryZh,
                        en: this.categoryEn,
                    }
                });
            }

            this.$router.push({
                path: '/admin/getCategories'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>